.table-total {
    color: $color-success;

    tr, th, td {
        border: none !important;
        padding: 5px 0;
        font-size: 1.1em;
        border-top: 1px solid black !important; } }

.table-extra {
    tr {
        border-bottom: 2px solid $color-primary; }

    td {
        font-weight: bold;
        border-top: 2px solid $color-primary !important;
        border-bottom: 2px solid $color-primary !important; } }

.table-total-error {
    color: $color-error; }

.table-deleted > * {
    opacity: .7; }

.table-cancelled > * {
    opacity: .6; }

.table-link-invisible {
    color: inherit; }

.table-title {
    font-weight: normal;
    color: $color-primary;
    font-size: 1.1rem; }

.table-hint {
    font-size: 10px;
    color: #777;
    margin-top: 1px;

    &:before {
        content: '*';
        color: $color-primary;
        display: inline-block;
        font-weight: bold; } }

.table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: $margin;
    // display: none

    @media (max-width: $screen-small) {
        margin-left: -$grid-gutter;
        width: calc(100% + #{$grid-gutter-width});
        border-left: 0;
        border-right: 0; }

    a[href^="mailto:"] {
        color: $color-neutral !important; }

    th {
        text-transform: uppercase; }

    th, td {
        text-align: left;
        padding: $padding $padding-l;
        // vertical-align: top

        // @media (max-width: $screen-small)
        //     display: block
        //     padding: $padding / 2 $grid-gutter

        //     &:first-child
        //         padding-top: $padding

        //     &:last-child
        //         padding-bottom: $padding

        // .form-uploader
 }        //     margin-top: 4px

    thead {
        // @media (max-width: $screen-small)
        //     display: none

        .table-subheader {
            text-transform: uppercase;
            color: #888; } }

    tbody tr {
        border-top: 1px solid $color-superlight;
        border-bottom: 1px solid $color-superlight; }

    tfoot {
        border: none;
        border-top: 2px solid $color-primary !important; }

    tfoot th,
    tfoot td {
        border-bottom: 0; }

    .table-actions {
        text-align: right;
        // white-space: nowrap
        border-left: none;

        a {
            display: inline-block; } }

    tbody {
        @each $type in $list-types {
            tr.table-#{nth($type, 1)} {
                background: nth($type, 3);

                th {
                    color: nth($type, 2); } } } } }

.table-simple {
    margin-left: -1 * $padding-l;
    width: calc(100% + 40px) !important;

    tbody tr {
        border-top: none;
        border-bottom: none; }

    th, td {} }

.table-counter {
    text-align: right !important;
    font-weight: normal;
    font-size: 12px; }

.table-order:after,
.table-order.reverse:after {
    display: inline-block;
    font-size: .6rem;
    vertical-align: middle;
    margin-left: 5px; }

.table-order:after {
    content: ' ▼'; }

.table-order.reverse:after {
    content: ' ▲'; }

.table-order.disabled:after {
    opacity: .25; }

// Stripped table

.table-stripped {
    tbody {
        tr:nth-child(odd) {
            background: lighten($color-neutral, 72); }

        @each $type in $list-types {
            tr:nth-child(odd).table-#{nth($type, 1)} {
                background: darken(nth($type, 3), 1.5); } } } }

// Bordered table

.table-bordered {
    border: 1px solid $color-superlight;
    background: white;

    th, td {
        border-bottom: 1px solid $color-superlight;
        // border-left: 1px solid $color-superlight

        &:last-child {
            border-right: 0; } } }

.table-bordered-full {
    th, td {
        border-left: 1px solid $color-superlight; } }

.table-invisible {
    border: none !important; }

// Compacted table

.table-compacted {
    font-size: 0.85rem;

    th, td {
        padding: $padding-s;

        @media (max-width: $screen-small) {
            padding: $padding-s / 2 $grid-gutter; } } }

// Hovered table

.table-hovered {
    tbody {
        tr:hover {
            background: darken($color-hyperlight, 1); }

        @each $type in $list-types {
            tr:hover.table-#{nth($type, 1)} {
                background: darken(nth($type, 3), 4); } } } }

.table-actions {
    .button {
        font-size: 10px; } }

.table-none {
    background: $color-table-none;
    color: grey;
    font-weight: bold; }

.table-green {
    background: $color-table-success;
    color: white;
    font-weight: bold; }

.table-red {
    background: $color-table-important;
    color: white;
    font-weight: bold; }

.table-orange {
    background: $color-table-warning;
    color: white;
    font-weight: bold; }

.table-color {
    padding: 2px 5px;
    border-radius: 4px; }

.table-comment {
    position: absolute;
    background: white;
    // border: 1px solid #888
    padding: 15px 20px;
    right: 90px;
    top: -10px;
    width: 450px;
    transition: .15s;
    transform: translateX(10px);
    box-shadow: 0 0 8px 2px rgba(black, .3);
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    text-align: left;
    white-space: pre-line; }

.table-comment-middle {
    top: calc(50% - 28px); }

.table-comment::after {
    background-color: white;
    box-shadow: 2px -2px 2px 0 rgba(black, .2);
    content: "\00a0";
    display: block;
    height: 15px;
    right: -3px;
    position: absolute;
    top: 25px;
    transform: rotate( 45deg ) translateY(-50%);
    width: 20px;
    z-index: 99; }

.table-comment-container {
    position: relative;
    cursor: pointer;

    &:hover .table-comment {
        transform: translateX(0);
        opacity: 1;
        visibility: visible; } }

.table-comment-actions {
    margin-top: 10px;
    text-align: right;

    a {
        color: $color-primary !important; }

    i {
        margin-right: 5px; } }

.table-too-large {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    table {
        max-width: 100%; } }
