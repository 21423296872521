$theme: 'cannes';

$theme-background: '/img/background-cannes.jpg';
$theme-logo-big:   '/img/logo-cannes.png';
$theme-logo-small: '/img/logo-cannes.png';
$theme-logo-big-height: 180px;

$theme-topbar-background: white;
$theme-topbar-color: black;

$color-primary:         #005F88;
$color-secondary:       #E04552;
$color-tertiary:        #2DAAE0;
$color-quartery:        #1F4E5F;
